import React from 'react';
import './App.css';

function App() {
  return (
    <div>
    <div className="App">
      <p>React App</p>
    </div>
    </div>
  );
}

export default App;
